<template>
  <div>
    <div
      class="card-body"
      v-if="$parent.modalDetalle.historicos_estados.length > 0"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="card card-success card-outline">
            <div class="card-header pt-2 pb-2">
              <h3 class="card-title">
                <i class="fas fa-user"></i>
                {{ $parent.modalDetalle.Nombres }}
              </h3>
              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body" style="display: block">
              <div class="row">
                <div class="col-md-12">
                  <table
                    id="docs"
                    class="table table-bordered table-hover table-sm text-center"
                  >
                    <thead class="thead-dark">
                      <tr>
                        <th>Fecha</th>
                        <th>Estado Anterior</th>
                        <th>Estado Nuevo</th>
                        <th>Observación</th>
                        <th>Responsable</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="estadosHis in $parent.modalDetalle
                          .historicos_estados"
                        :key="estadosHis.id"
                      >
                        <td class="texte-center">
                          {{ estadosHis.fecha_hora }}
                        </td>
                        <td class="texte-center">
                          <span
                            class="badge"
                            :class="[
                              estadosHis.estado_anterior == 1
                                ? 'badge-secondary'
                                : estadosHis.estado_anterior == 2
                                ? 'badge-success'
                                : estadosHis.estado_anterior == 3
                                ? 'badge-danger'
                                : estadosHis.estado_anterior == 4
                                ? 'bg-olive'
                                : estadosHis.estado_anterior == 5
                                ? 'badge-warning'
                                : estadosHis.estado_anterior == 6
                                ? 'badge-info'
                                : estadosHis.estado_anterior == 7
                                ? 'bg-navy'
                                : estadosHis.estado_anterior == 8
                                ? 'bg-lime'
                                : '',
                            ]"
                            >{{ estadosHis.nEstadoAnt }}</span
                          >
                        </td>
                        <td class="texte-center">
                          <span
                            class="badge"
                            :class="[
                              estadosHis.estado_nuevo == 1
                                ? 'badge-secondary'
                                : estadosHis.estado_nuevo == 2
                                ? 'badge-success'
                                : estadosHis.estado_nuevo == 3
                                ? 'badge-danger'
                                : estadosHis.estado_nuevo == 4
                                ? 'bg-olive'
                                : estadosHis.estado_nuevo == 5
                                ? 'badge-warning'
                                : estadosHis.estado_nuevo == 6
                                ? 'badge-info'
                                : estadosHis.estado_nuevo == 7
                                ? 'bg-navy'
                                : estadosHis.estado_nuevo == 8
                                ? 'bg-lime'
                                : '',
                            ]"
                            >{{ estadosHis.nEstadoNew }}</span
                          >
                        </td>
                        <td
                          class="texte-center"
                          v-text="estadosHis.observacion"
                        ></td>
                        <td class="texte-center">{{ estadosHis.user.name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <div class="info-box bg-gradient-warning">
        <span class="info-box-icon"
          ><i class="fas fa-exclamation-triangle"></i
        ></span>
        <div class="info-box-content">
          <span class="info-box-text">No tiene Histórico de Estados</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SolicitudConductorHistoricoEstado",
  data() {
    return {
      cargando: false,
    };
  },
  validations() {},
  methods: {},
  async mounted() {},
};
</script>
